.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.firework-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 200vh;
  z-index: -1;
}

.overlay-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
  height: calc(100vh - 40px);
  grid-template-rows: 280px 600px;
  overflow-x: hidden;
  
}

.border {
  width: 100%;
  height: 100%;
  border: 1px solid white;
  border-radius: 15px;
  border-width: 3px;
  border-style: outset;
  z-index: 1;
  margin: 9px;
  background: #fff;
  border-top-color: rgb(255, 146, 106); 
}


.inside {
   
  z-index: 1;

}

.black-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    height: calc(100vh - 40px);
  }

  .grid-item:nth-child(1) {
    order: 1;
  }

  .grid-item:nth-child(2) {
    order: 4;
  }

  .grid-item:nth-child(3) {
    order: 2;
  }

  .grid-item:nth-child(4) {
    order: 3;
  }

}

.flexbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.icon {
  padding: 5px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.left-column {
  padding-left: 2%;
}

.right-column {
  padding-right: 2%;
}

.spotify-container {
  padding-left: 2%;
}

.mixcloud-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
  border-radius: 50%;
  
}

.mixcloud-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.bodacious-icon {
  padding-right: 17px;
}

.bodacious-img {
  padding: 5px;
  padding-top: 5px;
  border-radius: 50%;
}

.bodacious-text {
  transform: translateY(-1px);

}


.grid-item {
  width: 90%;
  height: 100%;
  padding: 1%;
}

h1 {
  font-size: 60px;
  margin-bottom: 20px;
  letter-spacing: 10px;
}

h2 {
  font-size: 36px;
}

p {
  font-size: 24px;
}

.vhs-distortion {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.3;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(0, 0, 0, 0.1) 40%,
    rgba(0, 0, 0, 0.2) 60%,
    rgba(255, 255, 255, 0.1) 80%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background-size: 100% 3px;
  animation: vhs-distortion 0.5s infinite;
}

@keyframes vhs-distortion {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 3px;
  }
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  padding: 10px;
}

.button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 10px;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button:disabled {
  pointer-events: none;
}

.button:hover {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: none;
  transform: translateY(0);
}
