.soundcloudContainer {
    width: 100%;
    height: 30%;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .soundcloudIframe {
    width: 100%;
    height: 100%;
  }